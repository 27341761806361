    // CustomDropdown styles
    .dropdown-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 100%;
        color: $comment-title-text-color;
        white-space: nowrap;
        width: 30rem;
        padding-top: 2.5rem;
        margin-bottom: 12.5rem;

        .label-container {
            width: 100%;
            margin-bottom: 1rem;
            font-size: 1.6rem;
        }

        .input-container {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 4.2rem;
            background-color: $white;
            border: 0.1rem solid $pill-selection-icon-default;
            border-radius: 0.3rem;
            min-width: 12rem;
            max-width: 30rem;
            padding: 0 1.4rem;
            width: 100%;

            p {
                padding-right: 0.8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                -webkit-user-select: none;
                /* Safari */
                -ms-user-select: none;
                /* IE 10 and IE 11 */
                user-select: none;
                /* Standard syntax */
                width: 90%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 1.5rem;
            }
        }

        .disabled-dropdown {
            color: $disabled-text-color;
            border-color: $disabled-text-color;
            cursor: default;

            .la-TriangleDown:before {
                content: $disabled-text-color;
            }
        }

        .dropdown-popover {
            display: block;
            position: absolute;
            background-color: $white;
            top: 10rem;
            left: 0;
            z-index: 110;
            width: 30rem;
            border: 0.1rem solid $pill-selection-icon-default;
            border-radius: 0.3rem;

            &__options {
                max-height: 12rem;
                overflow-y: auto;

                &__option {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 1.3rem 0 1.3rem 1.3rem;
                    height: 4rem;
                    font-size: 1.5rem;

                    &:hover {
                        cursor: pointer;
                        background-color: #edf3f7;
                    }
                    .icon {
                        padding-right: 1rem;
                    }
                }
            }
        }
    }